/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

const ShareLink = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uri = queryParams.get('URI')
  useEffect(() => {
    if (uri) {
      const userAgent = navigator.userAgent
      console.log('userAgent', userAgent)
      const isAndroid = /android/i.test(userAgent)
      const isIOS = /iPad|iPhone|iPod/.test(userAgent)

      window.location.href = uri

      if (isAndroid) {
        setTimeout(() => {
          window.location.href = "https://play.google.com/store/apps/details?id=com.treeroots.bondbond"
        }, 2000)
      } else if (isIOS) {
        setTimeout(() => {
          window.location.href = "https://apps.apple.com/us/app/bondbond/id6503041224"
        }, 2000)
      } else {
        console.log('Not an iOS or Android device.')
      }
    }
  }, [])
  return <div></div>
}

export default ShareLink