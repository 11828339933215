import React, { Children } from "react"
import Spinner from "./Spinner"

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      loading,
      ...attributes
    },
    ref
  ) => {
    return (
      <button
        {...attributes}
        className={`w-[100%] md:w-auto ${attributes.disabled ? 'bg-[#F9F9F9] text-[#9DA4AE]' : 'bg-black text-white'} p-3 font-bold rounded-[5px] ${className}`}
        disabled={attributes.disabled || loading}
        ref={ref}
        onClick={attributes.onClick}
      >
        {
          loading ? <Spinner /> :
            Children.map(children, (child, i) => {
              return (
                <span key={i} className="mr-xsmall last:mr-0">
                  {child}
                </span>
              )
            })
        }
      </button>
    )
  }
)

export default Button
