/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import Loading from "../component/Loading";

const PaymentCompletePage = () => {
    // const queryParams = new URLSearchParams(window.location.search);
    // const txId = queryParams.get('txId')
    const uri = process.env.REACT_APP_BONDBOND_APP_DEEPLINK_URL || 'treeroots://bondbond.com'
    useEffect(() => {
        if (uri) {
            const userAgent = navigator.userAgent
            const isAndroid = /android/i.test(userAgent)
            const isIOS = /iPad|iPhone|iPod/.test(userAgent)

            if (isAndroid || isIOS) {
                window.location.href = uri
            } else {
                console.log('Not an iOS or Android device.');
            }
        }
    }, [])
    return <div className="flex items-center justify-center h-[90vh]">
        <Loading />
    </div>
}

export default PaymentCompletePage