/* eslint-disable react-hooks/exhaustive-deps */
import PasswordInput from "../component/PasswordInput"
import { useEffect, useMemo, useState } from "react"
import removeIcon from '../assets/remove.svg'
import checkIcon from '../assets/check.svg'
import Button from "../component/Button"
import { auth } from '../lib/firebase-config'
import { confirmPasswordReset, checkActionCode } from "firebase/auth"
import Logo from "../component/Logo"
import Loading from "../component/Loading"
import SuccessProcess from "./successProcess"
import FailProcess from "./failProcess"

const ResetPassword = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const actionCode = queryParams.get('oobCode') || ''
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [resetPasswordStatus, setResetPasswordStatus] = useState("pending")
  const [accountEmail, setAccountEmail] = useState("")
  const validatePassword = useMemo(() => {
    const minimalCharCondition = password.length >= 8
    const containNumber = /[0-9]/.test(password)
    const containUpperCase = /[A-Z]/.test(password)
    const containLowerCase = /[a-z]/.test(password)
    const containsSpecialChar = /[^A-Za-z0-9 ]/.test(password)
    const notContainWhiteSpace = password && !/\s/.test(password)
    return { minimalCharCondition, containNumber, containUpperCase, containLowerCase, containsSpecialChar, notContainWhiteSpace }
  }, [password])
  const { minimalCharCondition, containNumber, containUpperCase, containLowerCase, containsSpecialChar, notContainWhiteSpace } = validatePassword

  const handleResetPassword = async () => {
    setLoading(true)
    try {
      await confirmPasswordReset(auth, actionCode, password)
      setResetPasswordStatus("success")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setResetPasswordStatus("error")
    }
  }

  const verifyActionCode = async () => {
    try {
      const accountInfo = await checkActionCode(auth, actionCode)
      setAccountEmail(accountInfo.data.email || '')
      setResetPasswordStatus("processing")
    } catch (error) {
      setResetPasswordStatus("error")
    }
  }

  useEffect(() => {
    verifyActionCode()
  }, [])

  const renderProcess = () => {
    if (resetPasswordStatus === "error") {
      return <FailProcess mode='resetPassword' />
    }
    else if (resetPasswordStatus === "success") {
      return <SuccessProcess mode='resetPassword' />
    }
    else if (resetPasswordStatus === 'processing') {
      return <div>
        <Logo />
        <div className="mt-[30px]">
          <p className="font-medium text-[20px]">Reset password</p>
          <p className="text-[14px] mt-[15px]">Create new password for</p>
          <p className="text-[14px] mt-[5px] font-bold">{accountEmail}</p>
          <div>
            <PasswordInput placeholder="Enter your password *" value={password} onChange={(event) => setPassword(event.target.value)} />
            <div className="mt-[25px]" />
            <div className="flex">
              <img src={minimalCharCondition ? checkIcon : removeIcon} alt="validate icon" className="mr-[10px]" />
              <p className={minimalCharCondition ? 'text-[#19B26B]' : 'text-[#9EA3AE]'}>8 minimum characters</p>
            </div>
            <div className="flex">
              <img src={containNumber ? checkIcon : removeIcon} alt="validate icon" className="mr-[10px]" />
              <p className={containNumber ? 'text-[#19B26B]' : 'text-[#9EA3AE]'}>1 number</p>
            </div>
            <div className="flex">
              <img src={containUpperCase ? checkIcon : removeIcon} alt="validate icon" className="mr-[10px]" />
              <p className={containUpperCase ? 'text-[#19B26B]' : 'text-[#9EA3AE]'}>1 uppercase letter</p>
            </div>
            <div className="flex">
              <img src={containLowerCase ? checkIcon : removeIcon} alt="validate icon" className="mr-[10px]" />
              <p className={containLowerCase ? 'text-[#19B26B]' : 'text-[#9EA3AE]'}>1 lowercase letter</p>
            </div>
            <div className="flex">
              <img src={containsSpecialChar ? checkIcon : removeIcon} alt="validate icon" className="mr-[10px]" />
              <p className={containsSpecialChar ? 'text-[#19B26B]' : 'text-[#9EA3AE]'}>1 special character</p>
            </div>
            <div className="flex">
              <img src={notContainWhiteSpace ? checkIcon : removeIcon} alt="validate icon" className="mr-[10px]" />
              <p className={notContainWhiteSpace ? 'text-[#19B26B]' : 'text-[#9EA3AE]'}>No whitespaces</p>
            </div>
            <div className="mt-[25px]" />
            <PasswordInput placeholder="Confirm your password *"
              value={confirmPassword}
              onChange={(event) => {
                if (event.target.value === password) {
                  setError("")
                }
                setConfirmPassword(event.target.value)
              }}
              error={error}
              onBlur={() => {
                if (confirmPassword !== password) {
                  setError(`Password don't match`)
                }
              }}
            />
            <div className="mt-[50px]">
              <Button
                onClick={() => handleResetPassword()}
                disabled={(!minimalCharCondition || !containNumber || !containUpperCase || !containLowerCase || !containsSpecialChar) || (password !== confirmPassword) || !notContainWhiteSpace}
                className="md:!w-[100%]"
                loading={loading}
              >
                Reset password
              </Button>
            </div>
          </div>
        </div>
      </div>
    }
    return <div className="flex items-center justify-center h-[90vh]">
      <Loading />
    </div>
  }

  return <div className='flex justify-center'>
    <div className="w-full md:w-[320px]">
      {renderProcess()}
    </div>
  </div>
}

export default ResetPassword