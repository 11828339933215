import React, { useImperativeHandle, useState } from "react"
import eye from '../assets/eye.png'
import eyeOff from '../assets/eyeOff.png'
import eyeOffRed from '../assets/eye-off-red.svg'
import eyeRed from '../assets/eye-red.svg'
import lock from '../assets/lock.png'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string | null
}

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, placeholder, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const [showPassword, setShowPassword] = useState(false)
    const [isFocused, setIsFocused] = useState(false);
    useImperativeHandle(ref, () => inputRef.current!)
    return <div className="relative z-0 w-full text-base-regular">
      <img src={lock} className="pl-1 absolute left-0" alt="lock" />
      <input
        type={showPassword ? 'text' : "password"}
        className={`w-[100%] pl-[50px] pt-[16px] pr-[50px] border-0 border-b-2 border-gray-300 focus:outline-none transition duration-300 focus:bg-[#ECFAFF] focus:border-[#1694FF] ${(error && !isFocused) ? 'bg-[#FEF1F0] border-[#E70200] text-[#E70200]' : ''}`}
        placeholder={placeholder}
        {...props}
        ref={inputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={(event) => {
          if (props.onBlur) {
            props.onBlur(event)
          }
          setIsFocused(false)
        }}
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="px-4 focus:outline-none transition-all duration-150 outline-none absolute right-0"
      >
        {showPassword ? <img src={(error && !isFocused) ? eyeRed : eye} alt="eye" /> : <img src={(error && !isFocused) ? eyeOffRed : eyeOff} alt="eyeOff" />}
      </button>
      {(error && !isFocused) && <p className="text-[#E70200] pl-[17px] pt-[5px] text-[15px]">{error}</p>}
    </div>
  }
)

export default PasswordInput