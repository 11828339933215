/* eslint-disable react-hooks/exhaustive-deps */
import { applyActionCode } from 'firebase/auth'
import { auth } from '../lib/firebase-config'
import { useEffect, useState } from 'react';
import SuccessProcess from './successProcess';
import Loading from '../component/Loading';
import FailProcess from './failProcess';

const Verification = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const actionCode = queryParams.get('oobCode') || ''
  const [verificationStatus, setVerificationStatus] = useState("pending")
  const handleVerifyEmail = async () => {
    try {
      await applyActionCode(auth, actionCode)
      setVerificationStatus("success")
    } catch (error) {
      setVerificationStatus("error")
    }
  }

  useEffect(() => {
    handleVerifyEmail()
  }, [actionCode])
  const renderProcess = () => {
    if (verificationStatus === "success") {
      return <SuccessProcess mode='verifyEmail' />
    }
    if (verificationStatus === "error") {
      return <FailProcess mode='verifyEmail' />
    }
    return <div className="flex items-center justify-center h-[90vh]">
      <Loading />
    </div>
  }
  return <div className='flex justify-center'>
    <div className='w-full md:w-[320px]'>
      {renderProcess()}
    </div>
  </div>
}

export default Verification