import FailProcess from "../section/failProcess";
import ResetPassword from "../section/resetPassword";
import Verification from "../section/verification";

const AuthVerification = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get('mode')
  const renderSection = () => {
    if (mode === 'resetPassword') {
      return <ResetPassword />
    }
    else if (mode === 'verifyEmail') {
      return <Verification />
    }
    return <FailProcess mode="default" />
  }

  return renderSection()
}

export default AuthVerification
