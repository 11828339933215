
import Lottie from 'react-lottie'

import animationData from '../lottie/spinner-white.json'

const Spinner = ({
  height = 30,
  width = 30,
}: {
  height?: number
  width?: number
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  )
}

export default Spinner
