import Lottie from 'react-lottie'
import animationData from '../lottie/loading.json'

const Loading = ({
  height = 65,
  width = 65,
}: {
  height?: number
  width?: number
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <div>
    <Lottie options={defaultOptions} height={height} width={width} />
  </div>
}

export default Loading