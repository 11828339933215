import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FailProcess from "./section/failProcess";
import AuthVerification from "./page/authVerification";
import ShareLink from "./page/shareLink";
import PaymentPage from "./page/payment";
import PaymentCompletePage from "./page/paymentComplete";

function App() {
  return <div className="p-5 md:p-10">
    <Router>
      <Routes>
        <Route path="/auth_verification" element={<AuthVerification />} />
        <Route path="/share_link" element={<ShareLink />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/payment/complete" element={<PaymentCompletePage />} />
        <Route path="*" element={<FailProcess mode="default" />} />
      </Routes>
    </Router>
  </div>
}

export default App;
